
		import Async from '@preact/async-loader/async';

		function load(cb) {
			require.ensure([], function (require) {
				cb( require("!!../../../node_modules/babel-loader/lib/index.js??ref--4!./index.js") );
			}, "route-textfield");
		}

		export default Async(load);
	