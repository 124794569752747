// Components
import App from './components/app';

// Material Components
import '../../Typography/style.css';
import '../../Theme/style.css';

// Styles
import './style';

// Export
export default App;
